module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hasene Sverige","short_name":"Hasene Sverige","start_url":"/","background_color":"#ffffff","theme_color":"#00a8a6","display":"standalone","icon":"src/images/Hasene.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"1e4452b29b4e7f769112fddc0797f548"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
