const updatePrice = (state, id, price, type) => {
  return state.map((product) => {
    if (product.id === id) {
      return { ...product, price }
    } else {
      return product
    }
  })
}
const addProduct = (state, product, type) => {
  return [...state, product]
}

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      return addProduct(state, action.product, action.type)
    case 'INCREMENT_PRICE': {
      const { id, price, type } = action
      return updatePrice(state, id, price, type)
    }
    case 'DECREMENT_PRICE': {
      const { id, price, type } = action
      return updatePrice(state, id, price, type)
    }
    case 'REMOVE_FROM_CART':
      return state.filter((product) => product.id !== action.id)
    case 'CLEAR_CART':
      return []
    case 'SET_CART':
      return action.cart
    case 'SET_LOADING':
      return { ...state, loading: action.loading }
    default:
      return state
  }
}

export { cartReducer as default }
